import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import Media from "react-media";
import { isMobile } from "mobile-device-detect";
import {
  contentArrowStyle,
  contentStyleMobile,
  iconStyleMobile,
} from "./TimlineStyle";
import DataContent from "./DataContent";
import GroupedTimelineElement from "./GroupedTimelineElement";
import TimelineItem from "./TimlineItem";
import SingleTimelineItem from "./SingleTimelineItem";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";
import { PictureData } from "../../../types/PictureData";
import { GroupTimeline } from "./Types";

type GroupedTimelineGroupedTimelineProps = {
  data: {
    background: {
      color: string;
      picture: PictureData | null;
    };
    groups: Record<number, GroupTimeline>;
  };
};

const GroupedTimeline: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}) => {
  const { t } = useTranslation();
  const { locale: lang } = useRouter();

  const {
    data: {
      groups,
      background: { color: backgroundColor, picture: backgroundPicture },
    },
  } = blockData as GroupedTimelineGroupedTimelineProps;
  const timelineItems = Object.values(groups);

  const [currentPage, setCurrentPage] = React.useState(0);
  const isLastPage = currentPage === timelineItems.length - 1;

  const showMore = (): void => setCurrentPage(currentPage + 1);
  const showLess = (): void => setCurrentPage(0);

  return (
    <div
      className="groupedTimelineContainer"
      style={{
        backgroundImage: backgroundPicture
          ? `url("${process.env.NEXT_PUBLIC_IMG_URL}${backgroundPicture.publicUrl}")`
          : "none",
        backgroundColor: backgroundColor,
      }}
    >
      <div className="groupedTimeline">
        <Media query="(max-width: 1169px)" defaultMatches={isMobile}>
          {timelineItems.length === 1 ? (
            <SingleTimelineItem timelineItem={timelineItems[0]} />
          ) : (
            <VerticalTimeline>
              <>
                {timelineItems.map(
                  (timelineItem, index) =>
                    currentPage + 1 > index && (
                      <VerticalTimelineElement
                        key={timelineItem.title + index}
                        contentStyle={contentStyleMobile}
                        dateClassName="groupedTimeline__date"
                        contentArrowStyle={contentArrowStyle}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        date={
                          <div>
                            {timelineItem.items.map((item, index) => (
                              <TimelineItem
                                key={item.title + index}
                                itemData={item}
                              />
                            ))}
                          </div>
                        }
                        position="right"
                        iconStyle={iconStyleMobile}
                      >
                        <DataContent
                          item={timelineItem}
                          lang={lang as string}
                        />
                      </VerticalTimelineElement>
                    )
                )}
                <div className="groupedTimeline__itemSingleActions">
                  {!isLastPage ? (
                    <button
                      className="modern-button modern-button-small modern-button-outline-transparent"
                      onClick={showMore}
                    >
                      {t("schedule-show-more")}
                    </button>
                  ) : (
                    timelineItems.length > 1 && (
                      <button
                        className="modern-button modern-button-small modern-button-outline-transparent"
                        onClick={showLess}
                      >
                        {t("schedule-show-less")}
                      </button>
                    )
                  )}
                </div>
              </>
            </VerticalTimeline>
          )}
        </Media>

        <Media query="(min-width: 1170px)" defaultMatches={!isMobile}>
          <VerticalTimeline>
            <>
              {timelineItems.map(
                (timelineItem, index) =>
                  currentPage + 1 > index && (
                    <GroupedTimelineElement
                      isNotGroup={timelineItems.length === 1}
                      key={timelineItem.title + index}
                      timelineItem={timelineItem}
                      lang={lang as string}
                    />
                  )
              )}
              <div className="groupedTimeline__itemSingleActions">
                {!isLastPage ? (
                  <button
                    className="modern-button modern-button-small modern-button-outline-transparent"
                    onClick={showMore}
                  >
                    {t("schedule-show-more")}
                  </button>
                ) : (
                  timelineItems.length > 1 && (
                    <button
                      className="modern-button modern-button-small modern-button-outline-transparent"
                      onClick={showLess}
                    >
                      {t("schedule-show-less")}
                    </button>
                  )
                )}
              </div>
            </>
          </VerticalTimeline>
        </Media>
      </div>
    </div>
  );
};

export default GroupedTimeline;
