export const iconStyleMobile = {
  background: "#FABA20",
  width: "13px",
  height: "13px",
  marginLeft: "13px",
  marginTop: "15px",
  boxShadow: "none",
};

export const iconStyleDesktop = {
  ...iconStyleMobile,
  marginLeft: "-7px",
};

export const contentStyle = {
  background: "transparent",
  padding: 0,
  boxShadow: "none",
};

export const contentStyleMobile = {
  ...contentStyle,
  paddingRight: "10px",
  marginLeft: "40px",
};

export const contentArrowStyle = { display: "none" };
