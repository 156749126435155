import React from "react";
import { isAnchor, isFullUrl } from "../../../utils/validators";
import Link from "next/link";
import { GroupTimeline } from "./Types";

type DataContentProps = {
  item: GroupTimeline;
  lang: string;
  parentRef?: React.RefObject<HTMLDivElement>;
};

const DataContent: React.FunctionComponent<DataContentProps> = ({
  item,
  lang,
  parentRef,
}) => {
  return (
    <div ref={parentRef}>
      <h2 className="groupedTimeline__groupItemName">{item.title}</h2>
      <div
        className="groupedTimeline__groupItemContent"
        dangerouslySetInnerHTML={{ __html: item.content }}
      />
      {item.link && (
        <div className="groupedTimeline__groupItemActions">
          {isFullUrl(item.link) || isAnchor(item.link) ? (
            <a
              className="modern-button modern-button-primary modern-button-primary-outline modern-button-small groupedTimeline__groupItemLink"
              target={isAnchor(item.link) ? "_self" : "_blank"}
              rel="noopener noreferrer"
              href={item.link}
            >
              {item.link_label ? item.link_label : item.link}
            </a>
          ) : (
            <Link
              className="modern-button modern-button-primary modern-button-primary-outline modern-button-small groupedTimeline__groupItemLink"
              passHref
              href={`/${lang}${item.link}`}
            >
              {item.link_label ? item.link_label : item.link}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default DataContent;
