import React, { useEffect } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import {
  contentArrowStyle,
  contentStyle,
  iconStyleDesktop,
} from "./TimlineStyle";
import DataContent from "./DataContent";
import TimelineItem from "./TimlineItem";
import { chunk, get } from "lodash";
import { useTranslation } from "next-i18next";
import { GroupTimeline } from "./Types";

type GroupedTimelineElementProps = {
  timelineItem: GroupTimeline;
  lang: string;
  isNotGroup?: boolean;
};

const GroupedTimelineElement: React.FunctionComponent<
  GroupedTimelineElementProps
> = ({ timelineItem, lang, isNotGroup = false }) => {
  const ref = React.useRef(null);
  const padding = 30;
  const [rowHeight, setRowHeight] = React.useState("auto");
  const { t } = useTranslation();

  useEffect(() => {
    const elementHeight = get(ref, "current.clientHeight");

    if (elementHeight) {
      setRowHeight(`${elementHeight + padding}px`);
    }
  }, [ref]);

  const pages = chunk(timelineItem.items, 2);
  const [currentPage, setCurrentPage] = React.useState(0);
  const isLastPage = pages.length === 1 || currentPage === pages.length - 1;

  const showMore = (): void => setCurrentPage(currentPage + 1);
  const showLess = (): void => setCurrentPage(0);

  return isNotGroup ? (
    <>
      {pages.map(
        (page, pageIndex) =>
          currentPage >= pageIndex &&
          page.map((item, index) => (
            <VerticalTimelineElement
              key={item.title + index}
              contentStyle={{ ...contentStyle }}
              dateClassName="groupedTimeline__date"
              className="groupedTimeline__itemSingle"
              contentArrowStyle={contentArrowStyle}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              date={
                pageIndex === 0 &&
                index === 0 && (
                  <DataContent
                    parentRef={ref}
                    item={timelineItem}
                    lang={lang}
                  />
                )
              }
              position="right"
              iconStyle={iconStyleDesktop}
            >
              <TimelineItem key={item.title + index} itemData={item} />
            </VerticalTimelineElement>
          ))
      )}

      <div className="groupedTimeline__itemSingleActions">
        {!isLastPage ? (
          <button
            className="modern-button modern-button-small modern-button-outline-transparent"
            onClick={showMore}
          >
            {t("schedule-show-more")}
          </button>
        ) : (
          pages.length > 1 && (
            <button
              className="modern-button modern-button-small modern-button-outline-transparent"
              onClick={showLess}
            >
              {t("schedule-show-less")}
            </button>
          )
        )}
      </div>
    </>
  ) : (
    <VerticalTimelineElement
      contentStyle={{ ...contentStyle, minHeight: rowHeight }}
      dateClassName="groupedTimeline__date"
      contentArrowStyle={contentArrowStyle}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      date={<DataContent parentRef={ref} item={timelineItem} lang={lang} />}
      position="right"
      iconStyle={iconStyleDesktop}
    >
      {timelineItem.items.map((item, index) => (
        <TimelineItem key={item.title + index} itemData={item} />
      ))}
    </VerticalTimelineElement>
  );
};

export default GroupedTimelineElement;
