import React from "react";
import PictureProvider from "../../components/Picture";
import { GroupTimelineItem } from "./Types";

type TimelineItemProps = {
  itemData: GroupTimelineItem;
};
const TimelineItem: React.FunctionComponent<TimelineItemProps> = ({
  itemData,
}) => {
  return (
    <div className="groupedTimeline__item">
      {itemData.icon ? (
        <div className="groupedTimeline__item--img">
          <PictureProvider data={itemData.icon} />
        </div>
      ) : (
        <div className="groupedTimeline__item--noImg" />
      )}
      <div className="groupedTimeline__item--content">
        <h3>{itemData.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: itemData.content }} />
      </div>
    </div>
  );
};

export default TimelineItem;
