import React from "react";
import DataContent from "./DataContent";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  contentArrowStyle,
  contentStyleMobile,
  iconStyleMobile,
} from "./TimlineStyle";
import TimelineItem from "./TimlineItem";
import { chunk } from "lodash";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { GroupTimeline } from "./Types";

type SingleTimelineItemProps = {
  timelineItem: GroupTimeline;
};

const SingleTimelineItem: React.FunctionComponent<SingleTimelineItemProps> = ({
  timelineItem,
}) => {
  const pages = chunk(timelineItem.items, 2);
  const [currentPage, setCurrentPage] = React.useState(0);
  const isLastPage = pages.length === 1 || currentPage === pages.length - 1;
  const { t } = useTranslation();
  const { locale } = useRouter();

  const showMore = (): void => setCurrentPage(currentPage + 1);
  const showLess = (): void => setCurrentPage(0);
  return (
    <>
      <div className="groupedTimeline__dataContentMobile">
        <DataContent item={timelineItem} lang={locale as string} />
      </div>
      <VerticalTimeline>
        {pages.map(
          (page, pageIndex) =>
            currentPage >= pageIndex &&
            page.map((item, index) => (
              <VerticalTimelineElement
                key={index}
                contentStyle={contentStyleMobile}
                dateClassName="groupedTimeline__date"
                contentArrowStyle={contentArrowStyle}
                position="left"
                iconStyle={iconStyleMobile}
              >
                <div>
                  <TimelineItem key={item.title + index} itemData={item} />
                </div>
              </VerticalTimelineElement>
            ))
        )}
      </VerticalTimeline>
      <div className="groupedTimeline__itemSingleActions">
        {!isLastPage ? (
          <button
            className="modern-button modern-button-small modern-button-outline-transparent"
            onClick={showMore}
          >
            {t("schedule-show-more")}
          </button>
        ) : (
          pages.length > 1 && (
            <button
              className="modern-button modern-button-small modern-button-outline-transparent"
              onClick={showLess}
            >
              {t("schedule-show-less")}
            </button>
          )
        )}
      </div>
    </>
  );
};

export default SingleTimelineItem;
